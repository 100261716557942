import React, { ReactNode } from "react";
import Head from "next/head";

import Nav from "./Nav";
import Favicon from "./Favicon";

import clsx from "clsx";

import version from "~/version.preval";
import { env } from "~/env";

const Layout = ({
  children,
  pageTitle,
  pageDescription,
  type = "default",
  noJsRedirect,
  autoRefresh = false,
  hideLogo = false,
}: {
  children: ReactNode;
  pageTitle?: string;
  pageDescription?: string;
  /**
   * Lets the page pick between different layout modes:
   *  - fullscreen: Constrains layout to the screen width and height on Desktop.  This is used to create our fullscreen map layout.
   *  - default: A single column container used by most auxiliary pages.
   *  - wide: A wider container used for multi-column pages.
   */
  type?: "fullscreen" | "default" | "wide";
  /**
   * If the user does not have javascript enabled, where should we redirect the user?
   */
  noJsRedirect?: string;
  /**
   * Auto refresh the page every x seconds (used for pages designed to be viewed without javascript)
   */
  autoRefresh?: boolean;
  /**
   * Hide logo in top left
   */
  hideLogo?: boolean;
}) => {
  const title = pageTitle
    ? `${pageTitle} - Emergency Department Wait Times`
    : "Emergency Department Wait Times";

  return (
    <>
      <Head>
        <title>{title}</title>
        {pageDescription && (
          <meta name="description" content={pageDescription} />
        )}
        <Favicon />
        {noJsRedirect && (
          <noscript>
            <meta httpEquiv="refresh" content={`0; url=${noJsRedirect}`} />
          </noscript>
        )}
        {autoRefresh && <meta http-equiv="refresh" content="300" />}
      </Head>
      <div
        className={clsx({
          "flex h-full w-full flex-col md:h-screen": type === "fullscreen",
        })}
      >
        {/* Show warning on staging environments */}
        {env.NEXT_PUBLIC_ENVIRONMENT === "staging" && (
          <div className="bg-orange px-5 py-1.5 text-center text-sm font-medium text-orange-dark sm:text-base">
            <div>
              {
                "This is a staging environment used for testing, the information displayed may not be accurate."
              }
            </div>
          </div>
        )}
        <header className="sticky top-0 z-50 w-full bg-white px-5 py-5 drop-shadow-sm">
          <Nav hideLogo={hideLogo} />
        </header>
        {/* other layout components */}
        <main
          className={clsx({
            "w-full flex-grow md:overflow-y-hidden": type === "fullscreen",
            "mx-auto max-w-3xl px-3 pb-10 pt-10 xs:px-10 xs:pt-12 sm:pt-16":
              type === "default",
            "container mx-auto px-3 pb-10 pt-10 xs:px-5 xs:pt-12 sm:pt-16":
              type === "wide",
          })}
        >
          {children}
        </main>

        {type !== "fullscreen" && (
          <footer className="mx-auto max-w-2xl pb-10 text-center text-sm text-gray">
            <p className="text-sm">
              Created by{" "}
              <a
                href="https://vchdesign.ca"
                target="_blank"
                className="hover:text-[#eed05e]"
              >
                Learning Technologies
              </a>
            </p>
            <p className="mb-5 text-gray">© {new Date().getFullYear()}</p>
            <p className="text-gray-light">Version {version}</p>
          </footer>
        )}
      </div>
    </>
  );
};

export default Layout;
